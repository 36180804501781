



























































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
	name: "Home",
  metaInfo() {
    return {
      title: "Schön villanymotor-javító műhely",
      htmlAttrs: {
        lang: 'hu'
      },
      link: [
        { rel: 'stylesheet', href: "https://fonts.googleapis.com/css?family=Lato" },
        { rel: 'stylesheet', href: "https://fonts.googleapis.com/css?family=Catamaran:100,200,300,400,500,600,700,800,900" },
        { rel: 'stylesheet', href: "https://fonts.googleapis.com/css?family=Muli" },
        { rel: 'stylesheet', href: "https://use.fontawesome.com/releases/v5.6.3/css/all.css", integrity: "sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/", crossorigin: "anonymous" }
      ]
    };
  }
})
export default class Home extends Vue {}
